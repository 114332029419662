import {
  DesignedButton,
  ReusableSection,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { CatalogLayout } from "@with-nx/simple-ui/organisms";
import { Col, Row } from "antd";

export default function Page() {
  return (
    <CatalogLayout footerNoAngle title="Broadway Media - 404">
      <ReusableSection>
        <div className="container">
          <Row gutter={[36, 18]} align="middle" justify="center">
            <Col xs={24} sm={24} md={24} lg={8}>
              <img
                alt="404 Kittens"
                src="/assets/section/404.png"
                style={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                  pointerEvents: "none",
                  marginBottom: 24,
                  backgroundColor: "var(--background)",
                  mixBlendMode: "multiply",
                }}
              />
              <Rule rule="dm" display="block" bottom={24} color="var(--font1)">
                We're sorry
              </Rule>
              <Rule rule="ll" display="block" bottom={24} color="var(--font3)">
                The page you're looking for is not available for one of the
                following reasons:
              </Rule>
              <Rule rule="ls" display="block" bottom={24} color="var(--font4)">
                <ul>
                  <li>
                    The link to this page may be incorrect or out-of-date.
                  </li>
                  <li>You may have bookmarked a page that has been moved.</li>
                </ul>
              </Rule>
              <Rule rule="ls" display="block" bottom={24} color="var(--font4)">
                You can also contact us for help.
              </Rule>
              <DesignedButton
                icon="home/bold"
                theme="primary"
                label="Go Home"
                href="/"
              />
            </Col>
          </Row>
        </div>
      </ReusableSection>
    </CatalogLayout>
  );
}
